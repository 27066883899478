// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import "channels";

import * as ActiveStorage from "@rails/activestorage";
import Rails from "@rails/ujs";
import Splide from "@splidejs/splide";

Rails.start();
ActiveStorage.start();

function docReady(fn) {
  // see if DOM is already available
  if (document.readyState === "complete" || document.readyState === "interactive") {
    // call on next available tick
    setTimeout(fn, 1);
  } else {
    document.addEventListener("DOMContentLoaded", fn);
  }
}

docReady(function () {
  let InfiniteScroll = require("infinite-scroll");
  var box = document.querySelector(".talents");

  if (box !== null && document.querySelector(".pagination a[rel=next]") !== null) {
    var infScroll = new InfiniteScroll(box, {
      // options
      path: ".pagination a[rel=next]",
      append: ".featured-talents",
      history: false,
      debug: true,
    });

    infScroll.on("append", function (body, path, response) {
      console.log(response);
    });
  }

  var header = $("header");
  var sidebar = $("#sidebarMenu");
  var scrollTop = $("#scrollTop");
  var shrinkIt = header.height();

  $(window).on("scroll", function () {
    var scroll = $(window).scrollTop();
    if (scroll > shrinkIt) {
      header.addClass("shrink");
      sidebar.addClass("shrink");
      scrollTop.removeClass("hidden");
    } else if (scroll < shrinkIt - 50) {
      header.removeClass("shrink");
      sidebar.removeClass("shrink");
      scrollTop.addClass("hidden");
    }
  });
});

export function scrollToTop() {
  window.scrollTo({ top: 0, behavior: "smooth" });
}

export function scrollToAnchor(anchorId) {
  var anchor = document.getElementById(anchorId);
  anchor.scrollIntoView({ behavior: "smooth", block: "center" });
}

export function initSplide(splideQuerySelector) {
  new Splide(splideQuerySelector, {
    type: "loop",
    focus: "center",
    arrows: false,
    pagination: false,
    autoplay: true,
    padding: 25,
    trimSpace: false,
    lazyLoad: "nearby",
    height: "50vh",
    perPage: 4,
    breakpoints: {
      320: {
        perPage: 1,
      },
      480: {
        perPage: 1,
      },
      768: {
        perPage: 1,
      },
      992: {
        perPage: 2,
      },
      1200: {
        perPage: 3,
      },
      1800: {
        perPage: 4,
      },
    },
  }).mount();
}

export function initSplideShow(splideQuerySelector, height = "75vh", perPage = 1) {
  new Splide(splideQuerySelector, {
    type: "loop",
    focus: "center",
    arrows: true,
    pagination: false,
    autoplay: false,
    padding: 0,
    trimSpace: false,
    lazyLoad: "nearby",
    height: height,
    perPage: perPage,
  }).mount();
}

export * from "../select2-functions";
