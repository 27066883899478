import "select2";
import "select2/dist/css/select2.css";

import $ from "jquery";

export function initiliazeSelect2Tag(className) {
  $("." + className).select2({
    tags: false,
    closeOnSelect: false,
    placeholder: "Search for your skills...",
  });
}

export function initializeSelect2SingleTagCreation(className) {
  $("." + className).select2({
    tags: true,
    minimumResultsForSearch: 1,
    createTag: function (params) {
      var term = $.trim(params.term);

      if (term === "") {
        return {
          id: "-1",
          text: "Start typing to create or search for a client",
        };
      }

      return {
        id: term,
        text: `Create ${term} as new client`,
      };
    },
  });
  $("." + className).on("select2:open", (event) => {
    setTimeout(() => {
      const input = document.querySelector(".select2-search__field");
      input.placeholder = "Start typing to create or search for a client";
      input.focus();
    }, 0);
  });
}
